function NikshayIMEIValidation (rows) {
  // Validate headers
  const rowError = validateCsvHeadings(rows[0])
  if (rowError) {
    return `${rowError}`
  }
  // validate entries
  const dataRows = rows.slice(1, rows.length) // ignore header at 0 and get rest of the rows
  for (let i = 0; i < dataRows.length; i++) {
    const rowError = validateCsvRow(dataRows[i])
    if (rowError) {
      return `${rowError} on row ${i + 2}`
    }
  }
}
function validateCsvHeadings (row) {
  if (!row[0] || !/^IMEI$/.test(row[0])){
    return "Column 1 heading should be IMEI"
  } else if (!row[1] || !/^Level_3_HierarchyId$/.test(row[1])){
    return "Column 2 heading should be Level_3_HierarchyId"
  }
}
function validateCsvRow (row) {
  if (
    /[,]/g.test(row[0]) ||
        /[,]/g.test(row[1])
  ) {
    return "Remove every single comma ',' and try again"
  } else if (!row[0] || !/^[0-9]+$/.test(row[0])) {
    return `Column 1 should have a valid IMEI`
  } else if (!row[1] || !/^[0-9]*$/.test(row[1])) {
    return `Column 2 should have a valid Level_3_HierarchyId`
  }
}
module.exports = NikshayIMEIValidation
