<template>
  <LoginForm Heading = 'Nikshay IMEI Addition' @submit="submit"/>
</template>

<script>
import LoginForm from './Form.vue'
import { mapState, mapActions } from 'vuex'
import { DataForMatomoTracking, initializeNikshayMatomo, adminLogData } from '../../utils/matomoTracking'
import { apiResponseType } from '../../utils/dataValidations'
import { ApiClient } from '../store/api'
const NikshayIMEIValidation = require("./../../utils/NikshayValidations/NikshayIMEIValidation")
const formatNikshayImeiData = require("./../../utils/formatNikshayIMEIData")
const csv = require("papaparse")
export default {
  components: { LoginForm },
  computed: {
    ...mapState([
      'isProd'
    ])
  },
  mounted: function () {
    initializeNikshayMatomo()
  },
  methods: {
    ...mapActions([
      'loading',
      'setErrorMessage',
      'success'
    ]),
    async submit (file, filename, username, password) {
      this.loading(true)
      this.setErrorMessage({ isError: false, errMsg: '' })
      this.success({ isOk: false, succMsg: '' })
      let data = null
      const parsedCSV = csv.parse(file, {
        complete: async function (results){
          const validationError = NikshayIMEIValidation(results.data)
          if (validationError){
            this.setErrorMessage({ isError: true, errMsg: validationError })
            this.loading(false)
          } else {
            data = formatNikshayImeiData(results.data)
            let jsonUploadResponse
            try {
              jsonUploadResponse = await ApiClient.post(
                username,
                password,
                JSON.stringify(data),
                '/api/Hierarchy/AddImeisToHierarchy'
              )
            } catch (error) {
              this.setErrorMessage({ isError: true, errMsg: apiResponseType.JSONUploadFailed })
            }
            this.loading(false)
            if (jsonUploadResponse){
              if (jsonUploadResponse.data.success === true && jsonUploadResponse.data.data.failedImeiList == null){
                this.setErrorMessage({ isError: false, errMsg: '' })
                this.success({ isOk: true, succMsg: 'IMEIs are added.' })
              } else if (jsonUploadResponse.data.error != null){
                this.setErrorMessage({ isError: true, errMsg: jsonUploadResponse.data.error })
              } else if (Array.isArray(jsonUploadResponse.data.data.failedImeiList)) {
                var arrayobj = jsonUploadResponse.data.data.failedImeiList

                var outputlist = arrayobj.map(function (item) {
                  return "[ " + item.imei + " : " + item.level_3_HierarchyId + "]" + "\n"
                })

                this.setErrorMessage({ isError: true, errMsg: outputlist + ' following imeis were not added , please check the IMEI and Level_3_HierarchyId in DB' })
              } else {
                this.setErrorMessage({ isError: true, errMsg: 'Something went wrong' })
              }
              adminLogData('Nikshay IMEI Addition', 'StatusCode: ' + jsonUploadResponse.status, 'Filename: ' + filename)

              DataForMatomoTracking('Nikshay IMEI Addition', apiResponseType.SUCCESS, jsonUploadResponse.data.data.successImeiList)
              DataForMatomoTracking('Nikshay IMEI Addition', apiResponseType.FAILED, jsonUploadResponse.data.data.failedImeiList)
            }
          }
        }.bind(this)
      })
    }
  }
}
</script>
