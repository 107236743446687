function formatNikshayImeiData (csvFileAsArrays) {
  const fileRows = [...csvFileAsArrays]
  const listToSend = []
  fileRows.reduce((acc, row) => {
    const dataObj = {}
    dataObj[fileRows[0][0]] = row[0]
    dataObj[fileRows[0][1]] = row[1]
    listToSend.push(dataObj)
  })
  return listToSend
}
module.exports = formatNikshayImeiData
